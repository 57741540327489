// You probably don't want to modify this file, try ./index.tsx instead.

// Import our stylesheet statically, outside of the dynamic import below, so
// that it's added to the index.html rather than at runtime.
import './style.css'

// Standalone builds that support Webpack Module Federation must use a dynamic
// import like this to trampoline into the application so that shared modules
// work as expected. Otherwise you're likely to see an error like:
// Uncaught Error: Shared module is not available for eager consumption: webpack/sharing/consume/default/react/react
import('.')

export {}
